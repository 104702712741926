$(function () {
	//add device get device id
	$("#add-device select#input_7_3").on("change", function () {
		// Get the selected option
		var selectedOption = $(this).find("option:selected");
		// Get the data attribute value of the selected option
		var dataID = selectedOption.data("id");
		var deviceName = selectedOption.val();
		// Append the data attribute value to the hidden field
		$("#field_7_6 input[type='hidden']").val(dataID);
		//console.log(dataID);

		if (!deviceName == "") {
			$.ajax({
				url: "/wp-json/custom/v1/get-image/" + dataID,
				method: "GET",
				success: function (response) {
					if (response) {
						$("#add-device .device-image").html('<img src="' + response.url + '" alt="' + deviceName + '">');
					} else {
						$("#add-device .device-image").html("<p>No image found</p>");
					}
				},
				error: function () {
					$("#add-device .device-image").html("<p>Error fetching image</p>");
				},
			});
		} else {
			$("#add-device .device-image").html("<p>Select product name</p>");
		}
	});

	//registration get device id
	$("#registration-form select#input_3_14").on("change", function () {
		// Get the selected option
		var selectedOption = $(this).find("option:selected");
		// Get the data attribute value of the selected option
		var dataID = selectedOption.data("id");
		var deviceName = selectedOption.val();
		// Append the data attribute value to the hidden field
		$("#field_3_19 input[type='hidden']").val(dataID);
		//console.log(dataID);
	});

	// Listen for changes on the taxonomy select field
	$("#registration-form #input_3_13, #add-device #input_7_1").on("change", function () {
		// Update the selector to match your field ID
		var selectedTerm = $(this).val();

		const $select_id = $(this).attr("id");

		// Make an AJAX request to fetch posts
		$.ajax({
			url: customGF.ajax_url,
			type: "POST",
			data: {
				action: "get_posts_by_term",
				term: selectedTerm,
			},
			success: function (response) {
				// Clear existing options
				if ($select_id == "input_7_1") {
					var $update_select = "#input_7_3";
					$($update_select).empty();
				} else {
					var $update_select = "#input_3_14";
					$($update_select).empty();
				}

				// Check if there are any posts returned
				if (response.data.length > 0) {
					//append blank option first
					$($update_select).append(
						$("<option>", {
							value: "",
							text: "Product name",
						}).attr("data-id", "")
					);

					$("#add-device .device-image").html("<p>Select product name</p>");

					// Add new options
					$.each(response.data, function (index, post) {
						$($update_select).append(
							$("<option>", {
								value: post.value,
								text: post.text,
							}).attr("data-id", post.data_id)
						);
					});
				} else {
					// If no posts, display a default message
					$($update_select).append(
						$("<option>", {
							value: "",
							text: "No devices available",
						}).attr("data-id", "")
					);
				}
			},
		});
	});
});

//ADD DEVICE

$("#add-device #input_7_3").prop("disabled", true);

// Listen for changes on the first select field
$("#add-device #input_7_1").on("change", function () {
	// Check if the first select field has a value
	if ($(this).val()) {
		// Enable the second select field
		$("#add-device #input_7_3").prop("disabled", false);
	} else {
		// If the first select field has no value, keep the second select disabled
		$("#add-device #input_7_3").prop("disabled", true);
	}
});
